import { Card, Empty, Typography } from 'antd';
import React from 'react';

const { Title } = Typography;

import './styles.css';

const Assignments = () => {
  return (
    <Card
      bordered={false}
      title={<Title level={4}>Assignments</Title>}
      extra={<a href="*">More</a>}
      className="w-full rounded-2xl"
    >
      <Empty />
    </Card>
  );
};

export default React.memo(Assignments);
