import { Card, Empty, Typography } from 'antd';
import React from 'react';

const { Title } = Typography;

import './styles.css';

import Notifications from 'components/DashboardModule/Notifications';
import Announcements2 from 'components/DashboardModule/Announcements2';
import Calendar from 'components/DashboardModule/Calendar';
import Assignments from 'components/DashboardModule/Assignments';

const DefaultModule = () => {
  return (
    <Card
      bordered={false}
      title={<Title level={4}>Dashboard Modules</Title>}
      extra={<a href="*">More</a>}
      className="w-full rounded-2xl"
    >
      <Empty />
    </Card>
  );
};

export default React.memo(DefaultModule);

export const MODULES = {
  Notifications: Notifications,
  Announcements: Announcements2,
  Calendar: Calendar,
  Assignments: Assignments,
  default: DefaultModule,
} as any;
