import { Card, Divider, Empty, Spin, Typography } from 'antd';
import { getNotification } from 'api/notification';
import React from 'react';
import { useQuery } from 'react-query';
import moment from 'moment';
import { isEmpty } from 'lodash';
const { Title } = Typography;

import './styles.css';

function Notification() {
  const { data, isLoading } = useQuery(['getNotification'], () =>
    getNotification({ page: 1, limit: 10 }),
  );

  return (
    <Card
      bordered={false}
      title={<Title level={4}>Notifications</Title>}
      extra={<a href="/notifications">More</a>}
      className="w-full rounded-2xl"
    >
      <Spin spinning={isLoading} tip="Loading...">
        <div className="px-4 w-full flex flex-col">
          {isEmpty(data?.data?.listNotification) ? (
            <Empty />
          ) : (
            data?.data?.listNotification?.map((x: any) => (
              <div className="text-md" key={x.id}>
                {x.notification?.content} at{' '}
                {moment(x.notification?.updatedAt).format('YYYY/MM/DD, h:mm a')}
                <Divider />
              </div>
            ))
          )}
        </div>
      </Spin>
    </Card>
  );
}

export default React.memo(Notification);
